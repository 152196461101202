import React from "react";
import { Link } from "react-router-dom";
import { Table, Button, Popup } from "semantic-ui-react";
import Moment from "react-moment";
import "./RenderRowComponent.scss";
import {
  formatCurrency,
  formatCurrencyWithDecimals,
  formatNumber,
  formatNumberWithDecimal,
  formatCurrencyDivideBy1000,
  formatNumberDivideBy1000,
  formatPercentage,
} from "../../lib/formatters";
import { DATE_FORMAT, TIME_FORMAT } from "../Constants";
import "moment-timezone";
import { IconAction } from "./IconAction";

const buildActions = (actions, data, actionId) => {
  const actionList = Object.keys(actions);

  return actionList.map((key, index) => {
    const hide = actions[key].hide && actions[key].hide(data);

    if (actions[key].icon) {
      return (
        <React.Fragment key={index}>
          {!hide && (
            <IconAction title={key} data={data} action={actions[key]} />
          )}
        </React.Fragment>
      );
    } else if (actions[key].link) {
      const actionPathUrl = [actions[key].link, data[actionId]].join("");

      return (
        <Button
          basic
          size="mini"
          key={index}
          as={Link}
          to={actionPathUrl}
          className="index-table-action-button"
        >
          {key}
        </Button>
      );
    } else if (actions[key].onClick) {
      return (
        <Button
          secondary
          basic
          size="mini"
          key={index}
          disabled={actions[key].disabled(data)}
          onClick={() => actions[key].onClick(data)}
        >
          {key}
        </Button>
      );
    } else {
      return actions[key].modal(data);
    }
  });
};

export const RenderRowComponent = ({
  data,
  keys,
  tableRowIndex,
  actions,
  actionId,
  dateFields,
  numberFields,
  showCurrency,
  divideby1000NumberFields,
  percentageFields,
  rendererFields,
  rowSpanFields,
  fieldNameMappings,
  hideActions,
  rowSpanIndexDictionary,
  rowSpan,
  rowSpanInclude,
  decimalFields,
}) => {
  let firstColIndex = 0;

  function getRowSpanValue(lookup) {
    if (
      rowSpanIndexDictionary &&
      rowSpanIndexDictionary[lookup] &&
      rowSpanIndexDictionary[lookup][tableRowIndex]
    ) {
      return rowSpanIndexDictionary[lookup][tableRowIndex][
        Object.keys(rowSpanIndexDictionary[lookup][tableRowIndex])[0]
      ];
    } else {
      return null;
    }
  }

  function getRowSpanValueForIndexAndSetFirstColIndex(index, key) {
    let rowSpanValue = 1;

    if (rowSpan && rowSpan.includes(key)) {
      rowSpanValue = getRowSpanValue(key);
      if (rowSpanValue === null) {
        if (firstColIndex === index) {
          firstColIndex += 1;
        }

        return null;
      }
    }

    if (
      rowSpanInclude &&
      rowSpanInclude[key] &&
      rowSpan &&
      rowSpan.includes(rowSpanInclude[key].basedOffRowSpan)
    ) {
      rowSpanValue = getRowSpanValue(rowSpanInclude[key].basedOffRowSpan);
      if (rowSpanValue === null) {
        if (firstColIndex === index) {
          firstColIndex += 1;
        }
        return null;
      }
    }

    return rowSpanValue;
  }

  return keys.map((key, index) => {
    const isDate = dateFields ? dateFields.includes(key.toUpperCase()) : false;
    const isDecimalField = decimalFields
      ? decimalFields.includes(key.toUpperCase())
      : false;
    const isNumber = numberFields
      ? numberFields.includes(key.toUpperCase())
      : false;
    const isDivideBy1000 = divideby1000NumberFields
      ? divideby1000NumberFields.includes(key.toUpperCase())
      : false;
    const isPercentage = percentageFields
      ? percentageFields.includes(key.toUpperCase())
      : false;
    const actionsAreDefined = actions && actionId;
    const renderField =
      rendererFields && rendererFields.includes(key.toUpperCase());
    const rowSpanField =
      rowSpanFields && rowSpanFields.includes(key.toUpperCase());

    const rowSpanValue = getRowSpanValueForIndexAndSetFirstColIndex(index, key);
    if (rowSpanValue === null) {
      return null;
    }

    if (key === "actions") {
      if (actionsAreDefined) {
        return (
          <Table.Cell key={index} rowSpan={rowSpanValue}>
            {!hideActions ? buildActions(actions, data, actionId) : ""}
          </Table.Cell>
        );
      }
      return undefined;
    } else if (rowSpanField && (data.spanAmount || data.hideValueField)) {
      if (!data.hideValueField) {
        if (isNumber) {
          return (
            <Table.Cell
              key={index}
              textAlign="right"
              className="index-table-content-row"
              rowSpan={data.spanAmount || rowSpanValue}
            >
              {showCurrency
                ? formatCurrency(data[key])
                : formatNumber(data[key])}
            </Table.Cell>
          );
        } else {
          return (
            <Table.Cell
              key={index}
              className="index-table-content-row"
              rowSpan={data.spanAmount || rowSpanValue}
            >
              {data[key]}
            </Table.Cell>
          );
        }
      } else {
        return null;
      }
    } else if (renderField) {
      if (isNumber) {
        return (
          <Table.Cell
            key={index}
            textAlign="right"
            className="index-table-content-row"
            rowSpan={rowSpanValue}
          >
            {fieldNameMappings[key.toUpperCase()].render(data)}
          </Table.Cell>
        );
      } else {
        return (
          <Table.Cell
            key={index}
            className="index-table-content-row"
            rowSpan={rowSpanValue}
          >
            {fieldNameMappings[key.toUpperCase()].render(data)}
          </Table.Cell>
        );
      }
    } else if (isDate) {
      return (
        <Popup
          key={index}
          content={
            <Moment
              utc
              local
              tz={Intl.DateTimeFormat().resolvedOptions().timeZone}
              format={TIME_FORMAT}
            >
              {data[key]}
            </Moment>
          }
          trigger={
            <Table.Cell
              key={index}
              className="index-table-content-row"
              rowSpan={rowSpanValue}
            >
              <Moment format={DATE_FORMAT}>{data[key]}</Moment>
            </Table.Cell>
          }
        />
      );
    } else if (isDecimalField) {
      return (
        <Table.Cell
          key={index}
          textAlign="right"
          className="index-table-content-row"
          rowSpan={rowSpanValue}
        >
          {showCurrency
            ? formatCurrencyWithDecimals(data[key])
            : formatNumberWithDecimal(data[key])}
        </Table.Cell>
      );
    } else if (isNumber && isDivideBy1000) {
      return (
        <Table.Cell
          key={index}
          textAlign="right"
          className="index-table-content-row"
          rowSpan={rowSpanValue}
        >
          {showCurrency
            ? formatCurrencyDivideBy1000(data[key])
            : formatNumberDivideBy1000(data[key])}
        </Table.Cell>
      );
    } else if (isNumber) {
      return (
        <Table.Cell
          key={index}
          textAlign="right"
          className="index-table-content-row"
          rowSpan={rowSpanValue}
        >
          {showCurrency ? formatCurrency(data[key]) : formatNumber(data[key])}
        </Table.Cell>
      );
    } else if (isPercentage) {
      return (
        <Table.Cell
          key={index}
          textAlign="right"
          className="index-table-content-row"
          rowSpan={rowSpanValue}
        >
          {formatPercentage(data[key])}
        </Table.Cell>
      );
    } else {
      return (
        <Table.Cell
          key={index}
          className="index-table-content-row"
          rowSpan={rowSpanValue}
        >
          {data[key]}
        </Table.Cell>
      );
    }
  });
};
